
<template>
  <div style="margin-left:100px;margin-top:50px;margin-right:100px;">
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-container>
        <el-header class="header">新增商品</el-header>
        <el-main>
          <el-form-item label="标题" prop="goodsTitle">
            <el-input v-model="dataForm.goodsTitle" placeholder="标题"></el-input>
          </el-form-item>
          <el-form-item label="营销短语" prop="sellPhrase">
            <el-input v-model="dataForm.sellPhrase" placeholder="营销短语" maxlength="15"></el-input>
          </el-form-item>
          <el-form-item label="商品分类" prop="mallGoodsCategoryId">
            <el-select v-model="dataForm.mallGoodsCategoryId" placeholder="请选择分类">
              <el-option
                v-for="item in categoryList"
                :key="item.categoryName"
                :label="item.categoryName"
                :value="item.mallGoodsCategoryId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="商品图片" prop="goodsPic">
            <el-upload
              class="avatar-uploader"
              :action="url"
              :show-file-list="false"
              :on-success="successHandle"
              :before-upload="beforeUploadHandle"
            >
              <img v-if="imageUrl" :src="imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="轮播图" prop="picList">
            <el-upload
              class="upload-demo"
              :action="multiUrl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :file-list="fileList"
              :on-success="successMultiHandle"
              :before-upload="beforeMultiUploadHandle"
              list-type="picture"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">只能上传jpeg/png/bmp文件，且不超过2MB</div>
            </el-upload>
          </el-form-item>
          <el-form-item label="商品详情" prop="goodsDetail">
            <tinymce ref="tinymce" style="width: 100%" v-model="dataForm.goodsDetail" />
          </el-form-item>
          <!-- <el-form-item label="商品详情" prop="goodsDetail" style="height:400px;">
            <quill-editor ref="myTextEditor" v-model="dataForm.goodsDetail" :options="editorOption" style="height:350px;width:80%;"></quill-editor>
          </el-form-item>-->
        </el-main>
      </el-container>
      <el-container>
        <el-header class="header">
          商品属性
          <el-button class="button_left" type="success" @click="dialogFormVisible = true">添加商品属性</el-button>
        </el-header>
        <el-main>
          <el-form-item v-for="attr in checkedAttrList" :key="attr.name">
            <el-row>
              <el-tag class="tag_no_border" type="info">属性名：</el-tag>
              <el-tag class="attr_input">&nbsp;{{attr.name}}&nbsp;</el-tag>
              <!-- <el-alert>属性名：</el-alert><el-alert class="attrName">{{attr.name}}</el-alert> -->
            </el-row>
            <el-row>
              <el-tag class="tag_no_border" type="info">属性值：</el-tag>
              <el-tag
                class="tag"
                v-for="tag in attr.attrValues"
                :key="tag.name"
                closable
                :type="tag.type"
                @close="handleClose(attr.id, tag.id)"
              >&nbsp;{{tag.name}}&nbsp;</el-tag>
              <el-button
                class="button_no_border"
                size="mini"
                @click="getAttrValueDataList(attr.id)"
              >+&nbsp;添加</el-button>
            </el-row>
          </el-form-item>
        </el-main>
      </el-container>
      <el-container>
        <el-header class="header">SKU明细</el-header>
        <el-main>
          <el-form-item>
            <el-table
              :data="skuTableData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column prop="goodsSkuName" label="名称">
                <template slot-scope="scope">
                  <el-input
                    class="input_style"
                    size="mini"
                    v-model="scope.row.goodsSkuName"
                    @input="handleInput(scope.row.mallGoodsSkuId,scope.row.goodsSkuName,'goodsSkuName')"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column
                v-for="attr in skuAttrList"
                :prop="'attr'+attr.id"
                :key="attr"
                :label="attr.name"
              ></el-table-column>
              <el-table-column prop="goodsSkuOriginalPrice" label="价格">
                <template slot-scope="scope">
                  <el-input
                    class="input_style"
                    size="mini"
                    v-model="scope.row.goodsSkuOriginalPrice"
                    @input="handleInput(scope.row.mallGoodsSkuId,scope.row.goodsSkuOriginalPrice,'goodsSkuOriginalPrice')"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="goodsSkuNum" label="库存">
                <template slot-scope="scope">
                  <el-input
                    class="input_style"
                    size="mini"
                    v-model="scope.row.goodsSkuNum"
                    @input="handleInput(scope.row.mallGoodsSkuId,scope.row.goodsSkuNum,'goodsSkuNum')"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="goodsSkuPayPrice" label="折扣价">
                <template slot-scope="scope">
                  <el-input
                    class="input_style"
                    size="mini"
                    v-model="scope.row.goodsSkuPayPrice"
                    @input="handleInput(scope.row.mallGoodsSkuId,scope.row.goodsSkuPayPrice,'goodsSkuPayPrice')"
                  ></el-input>
                </template>
              </el-table-column>
              <el-table-column label="预览图">
                <template slot-scope="scope">
                  <el-upload
                    class="avatar-uploader"
                    :action="uploadSkuPath(scope.row.index)"
                    :show-file-list="false"
                    :on-success="successSkuHandle"
                    :before-upload="beforeSkuUploadHandle"
                  >
                    <img :src="skuImageUrl" v-if="skuImageUrl" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </template>
              </el-table-column>
              <el-table-column prop="isShelf" label="状态">
                <template slot-scope="scope">
                  <i v-if="scope.row.isShelf=='0'">下架中</i>
                  <i v-else-if="scope.row.isShelf=='1'">上架中</i>
                  <i v-else>下架中</i>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    v-if="scope.row.isShelf=='0'"
                    size="mini"
                    @click="modifyState(scope.row.index,1)"
                  >上架</el-button>
                  <el-button
                    type="primary"
                    v-else-if="scope.row.isShelf=='1'"
                    size="mini"
                    @click="modifyState(scope.row.index,0)"
                  >下架</el-button>
                  <el-button
                    type="primary"
                    v-else
                    size="mini"
                    @click="modifyState(scope.row.index,1)"
                  >上架</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
        </el-main>
        <el-footer>
          <el-button type="primary" @click="dataFormSubmit()">保存</el-button>
          <el-button type="info" @click="returnMallGoodsMain()">返回</el-button>
        </el-footer>
      </el-container>
    </el-form>
    <!-- 商品属性弹出框 -->
    <el-dialog title="商品属性" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="商品属性" :label-width="formLabelWidth">
          <el-select v-model="form.mallGoodsAttrId" multiple placeholder="请选择商品属性">
            <el-option
              v-for="item in attrList"
              :key="item.mallGoodsAttrId"
              :label="item.attrName"
              :value="item.mallGoodsAttrId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="chooseAttr()">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 商品属性值弹出框-->
    <el-dialog title="商品属性值" :visible.sync="attrValueDialogFormVisible">
      <el-form :model="valueForm">
        <el-form-item label="商品属性值" :label-width="formLabelWidth">
          <el-select v-model="valueForm.mallGoodsAttrValueId" multiple placeholder="请选择商品属性值">
            <el-option
              v-for="item in attrValueList"
              :key="item.mallGoodsAttrValueId"
              :label="item.attrValueName"
              :value="item.mallGoodsAttrValueId"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="attrValueDialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="chooseAttrValue(valueForm.attrId)">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}
.avatar {
  width: 40px;
  height: 40px;
  display: block;
}
.attrName {
  width: 250px;
  height: 42px;
}
.header {
  /* background-color: #5588aa;
  color: white;
  font-size: 14px; */
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
  line-height: 58px;
  padding: 0;
}
.button_left {
  margin-left: 20px;
}
.tag {
  margin-right: 5px;
}
.tag_no_border {
  border: 0px;
  background-color: white;
}
.button_no_border {
  border: 0px;
  color: #1196ee;
}
.attr_input {
  border: 0px;
  background-color: white;
  color: #808080;
}
.input_style {
  width: 100px;
  border: 0px solid #808080;
}
.upload-demo {
  width: 300px;
}
</style>
<script src="https://cdn.jsdelivr.net/npm/vue/dist/vue.js"></script>
<script>
import { treeDataTranslate } from "@/utils";
import {
  categoryListNoPagination,
  attrListNoPagination,
  mallGoodsMainList,
  findMallGoodsMainInfo,
  mallGoodsMainDataFormSubmit,
  delMallGoodsMainValue,
  attrValueListByAttrId
} from "@/api/sys";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
import { getToken } from "@/utils/auth";
import Tinymce from "@/components/Tinymce";
export default {
  data() {
    return {
      loading: false,
      visible: false,
      // 商品表单
      dataForm: {
        id: "",
        goodsTitle: "",
        mallGoodsCategoryId: "",
        goodsDetail: "",
        sellPhrase: ""
      },
      editorOption: {
        placeholder: "编辑文章内容"
      },
      categoryList: [],
      // 弹出框属性列表
      attrList: [],
      // 弹出框属性值列表
      attrValueList: [],
      // 选中的属性列表
      checkedAttrList: [],
      checkedAttrIdList: [],
      // 选中的属性值列表
      dialogFormVisible: false,
      attrValueDialogFormVisible: false,
      // 商品属性弹出框表单
      form: {
        mallGoodsAttrId: ""
      },
      // 商品属性值弹出框表单
      valueForm: {
        mallGoodsAttrValueId: "",
        attrId: ""
      },
      formLabelWidth: "120px",
      multiUrl: `${
        process.env.VUE_APP_BASE_API
      }sys/oss/multiUpload?token=${getToken()}`,
      url: `${process.env.VUE_APP_BASE_API}sys/oss/upload?token=${getToken()}`,
      skuPath: "",
      imageUrl: "",
      skuImageUrl: "",
      fileList: [],
      dialogImageUrl: "",
      dialogVisible: false,
      dataRule: {
        attrName: [
          { required: true, message: "商品标题不能为空", trigger: "blur" }
        ],
        mallGoodsCategoryId: [
          { required: true, message: "商品分类不能为空", trigger: "blur" }
        ]
      },
      // SKU明细数据
      skuAttrList: [],
      skuTableData: [],
      multipleSelection: [] //批量修改数据
    };
  },
  components: {
    // quillEditor,
    Tinymce
  },
  methods: {
    async init() {
      let mallGoodsMainId = this.$route.query.mallGoodsMainId;
      if (
        mallGoodsMainId == undefined ||
        mallGoodsMainId == null ||
        mallGoodsMainId == ""
      ) {
      } else {
        this.dataForm.id = mallGoodsMainId;
        const { data } = await findMallGoodsMainInfo(
          `/mall/mallgoodsmain/info/${mallGoodsMainId}`
        );
        if (data && data.code === 0) {
          this.dataForm.goodsTitle = data.mallGoodsMain.goodsTitle;
          this.dataForm.goodsDetail = data.mallGoodsMain.goodsDetail;
          this.dataForm.sellPhrase = data.mallGoodsMain.sellPhrase;
          this.dataForm.mallGoodsCategoryId =
            data.mallGoodsMain.mallGoodsCategoryId;
          this.imageUrl = data.mallGoodsMain.goodsImage;
          this.form.mallGoodsAttrId = data.attrIds;
          this.checkedAttrIdList = data.checkedAttrIdList;
          this.checkedAttrList = data.checkedAttrList;
          this.skuAttrList = data.checkedAttrList;
          this.fileList = data.picList;
          this.skuTableData = data.skuDataList;
        }
      }
    },
    // 获取数据列表
    async getDataList() {
      let params = {};
      const { data } = await categoryListNoPagination(params);
      if (data && data.code === 0) {
        this.categoryList = data.categoryList;
      } else {
        this.categoryList = [];
      }
    },
    // 返回商品列表页面
    returnMallGoodsMain() {
      this.$router.push({ path: "/mall/goods/main", query: {} });
    },
    // 弹出框中属性列表
    async getAttrDataList() {
      let params = {};
      const { data } = await attrListNoPagination(params);
      if (data && data.code === 0) {
        this.attrList = data.attrList;
      } else {
        this.attrList = [];
      }
    },
    onEditorChange({ editor, html, text }) {
      this.dataForm.goodsDetail = html;
    },
    buildSkuDataList() {
      this.skuAttrList = [];
      this.checkedAttrList.forEach(attr => {
        var attrValueIds = attr.attrValueIds;
        if (
          attrValueIds != undefined &&
          attrValueIds != null &&
          attrValueIds.length > 0
        ) {
          this.skuAttrList.push(attr);
        }
      });
      this.skuTableData = [];
      var length = this.skuAttrList.length;
      var index = 0;
      // 暂只支持2个属性组合
      if (length == 1) {
        var skuAttr = this.skuAttrList[0];
        var valueList = skuAttr.attrValues;
        valueList.forEach(v => {
          var valueObj = new Object();
          valueObj.mallGoodsSkuId = Math.floor(Math.random() * 10000000); //需要JS生成一个UUID,保证不重复
          valueObj.goodsSkuName = "";
          this.$set(valueObj, "attr" + skuAttr.id, v.name);
          valueObj.goodsSkuOriginalPrice = 0;
          valueObj.goodsSkuNum = 0;
          valueObj.goodsSkuPayPrice = 0;
          valueObj.goodsSkuDouPrice = 0;
          valueObj.goodsSkuJdPrice = 0;
          valueObj.index = index++;
          valueObj.goodsSkuPic = "";
          var valueIds = [];
          valueIds.push(v.id);
          valueObj.attrValueIds = valueIds;
          valueObj.isShelf = 0;
          this.skuTableData.push(valueObj);
        });
      } else if (length == 2) {
        var skuAttr0 = this.skuAttrList[0];
        var skuAttr1 = this.skuAttrList[1];
        skuAttr0.attrValues.forEach(s0 => {
          var valueIds = [];
          skuAttr1.attrValues.forEach(s1 => {
            var valueObj = new Object();
            valueObj.mallGoodsSkuId = Math.floor(Math.random() * 10000000); //需要JS生成一个UUID,保证不重复
            valueObj.goodsSkuName = "";
            this.$set(valueObj, "attr" + skuAttr0.id, s0.name);
            this.$set(valueObj, "attr" + skuAttr1.id, s1.name);
            valueObj.goodsSkuOriginalPrice = 0;
            valueObj.goodsSkuNum = 0;
            valueObj.goodsSkuPayPrice = 0;
            valueObj.goodsSkuDouPrice = 0;
            valueObj.goodsSkuJdPrice = 0;
            valueObj.index = index++;
            valueObj.goodsSkuPic = "";
            valueIds.push(s0.id);
            valueIds.push(s1.id);
            valueObj.attrValueIds = valueIds;
            valueObj.isShelf = 0;
            this.skuTableData.push(valueObj);
            valueIds = [];
          });
        });
        console.log(this.skuTableData);
        debugger;
      } else if (length > 2) {
        this.skuAttrList[i].attrValues.forEach(a => {
          this.skuAttrList[i].attrValues.forEach(b => {
            if (3 < length) {
              this.skuAttrList[i].attrValues.forEach(b => {});
            }
          });
        });
        if (this.skuAttrList[0].attrValues != null)
          var skuAttr0 = this.skuAttrList[0];
        var skuAttr1 = this.skuAttrList[1];
      }
    },
    // 商品保存
    dataFormSubmit() {
      this.$refs["dataForm"].validate(async valid => {
        var picList = [];
        if (this.fileList != null) {
          this.fileList.forEach(pic => {
            picList.push(pic.url);
          });
        }
        if (valid) {
          const params = {
            mallGoodsMainId: this.dataForm.id || undefined,
            goodsTitle: this.dataForm.goodsTitle,
            goodsDetail: this.dataForm.goodsDetail,
            sellPhrase: this.dataForm.sellPhrase,
            mallGoodsCategoryId: this.dataForm.mallGoodsCategoryId,
            skuTableData: this.skuTableData,
            picList: picList,
            goodsImage: this.imageUrl,
            mallGoodsCategoryId: this.dataForm.mallGoodsCategoryId
          };
          if (params.skuTableData.length == 0) {
            this.$message.error("商品SKU不能为空");
            return false;
          }

          const { data } = await mallGoodsMainDataFormSubmit(
            `/mall/mallgoodsmain/${!this.dataForm.id ? "save" : "update"}`,
            params
          );
          if (data && data.code === 0) {
            this.$message({
              message: "操作成功",
              type: "success",
              duration: 1500,
              onClose: () => {
                this.sideClose();
                this.$emit("refreshDataList");
              }
            });
            this.$router.push({ path: "/mall/goods/main", query: {} });
          } else {
            this.$message.error(data.msg);
          }
        }
      });
    },
    // 属性选中事件
    chooseAttr() {
      const attrId = this.form.mallGoodsAttrId;
      if (attrId != null && attrId != "") {
        var attrIds = attrId.toString().split(",");
        if (attrIds.length > 2) {
          this.$message.error("最多只能选择2个属性");
          return false;
        }
        var tempCheckedAttrList = [];
        var tempCheckedAttrIdList = [];
        this.attrList.forEach(row => {
          var attrId = row.mallGoodsAttrId;
          if (attrIds.includes(attrId)) {
            if (!this.checkedAttrIdList.includes(attrId)) {
              var obj = new Object();
              obj.id = attrId;
              obj.name = row.attrName;
              // 属性值
              var attrValues = [];
              var attrValueIds = [];
              obj.attrValues = attrValues;
              obj.attrValueIds = attrValueIds;
              tempCheckedAttrList.push(obj);
              tempCheckedAttrIdList.push(attrId);
            } else {
              this.checkedAttrList.forEach(attr2 => {
                if (attrId === attr2.id) {
                  tempCheckedAttrList.push(attr2);
                  tempCheckedAttrIdList.push(attrId);
                }
              });
            }
          }
        });
        this.checkedAttrList = tempCheckedAttrList;
        this.checkedAttrIdList = tempCheckedAttrIdList;
      } else {
        this.form.mallGoodsAttrId = [];
        this.checkedAttrList = [];
        this.checkedAttrIdList = [];
      }
      this.buildSkuDataList();
      this.dialogFormVisible = false;
    },
    // 属性值弹出框
    async getAttrValueDataList(attrId) {
      let params = {
        mallGoodsAttrId: attrId
      };
      const { data } = await attrValueListByAttrId(params);
      if (data && data.code === 0) {
        this.attrValueList = data.attrValueList;
        this.valueForm.attrId = attrId;
        console.log(this.checkedAttrList);
        this.checkedAttrList.forEach(attr => {
          // 选择当前属性的属性值
          if (attr.id === attrId) {
            var attrValueIds = attr.attrValueIds;
            if (attrValueIds != null && attrValueIds.length > 0) {
              this.valueForm.mallGoodsAttrValueId = attrValueIds;
            } else {
              this.valueForm.mallGoodsAttrValueId = [];
            }
          }
        });
      } else {
        this.attrValueList = [];
      }
      this.attrValueDialogFormVisible = true;
    },
    // 属性值弹出框选中值
    chooseAttrValue(attrId) {
      // 动态拼接商品属性信息
      const attrValueId = this.valueForm.mallGoodsAttrValueId;
      if (attrValueId != null && attrValueId != "") {
        var attrValueIds = attrValueId.toString().split(",");
        this.checkedAttrList.forEach(tempAttr => {
          var checkedValueIds = tempAttr.attrValueIds;
          var checkedValueList = tempAttr.attrValues;
          if (attrId === tempAttr.id) {
            var checkedValues = [];
            var checkedValueIds = [];
            this.attrValueList.forEach(tempValue => {
              var valueId = tempValue.mallGoodsAttrValueId;
              if (attrValueIds.includes(valueId)) {
                if (!checkedValueIds.includes(valueId)) {
                  var objValue = new Object();
                  objValue.id = valueId;
                  objValue.name = tempValue.attrValueName;
                  objValue.type = "success";
                  checkedValues.push(objValue);
                  checkedValueIds.push(valueId);
                } else {
                  checkedValueList.forEach(value => {
                    if (valueId === value.id) {
                      checkedValues.push(value);
                      checkedValueIds.push(valueId);
                    }
                  });
                }
              }
            });
            tempAttr.attrValues = checkedValues;
            tempAttr.attrValueIds = checkedValueIds;
          }
        });
      } else {
        this.valueForm.mallGoodsAttrValueId;
        this.checkedAttrList.forEach(tempAttr => {
          if (attrId === tempAttr.id) {
            tempAttr.attrValueIds = [];
            tempAttr.attrValues = [];
          }
        });
      }
      this.attrValueDialogFormVisible = false;
      this.buildSkuDataList();
    },
    // ========================主商品上传图片功能=====================
    // 上传之前
    beforeUploadHandle(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error("上传图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传成功
    successHandle(response, file, fileList) {
      if (response && response.code === 0) {
        this.imageUrl = response.data.fileUrl;
      } else {
        this.$message.error(response.msg);
      }
    },
    // ========================主商品上传图片功能=====================
    // ========================SKU商品=======================
    uploadSkuPath(index) {
      return (
        `${process.env.VUE_APP_BASE_API}/sys/oss/upload?token=${getToken()}` +
        `&index=` +
        index
      );
    },
    // 上传之前
    beforeSkuUploadHandle(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传成功
    successSkuHandle(response, file, fileList) {
      if (response && response.code === 0) {
        this.skuImageUrl = response.data.fileUrl;
      } else {
        this.$message.error(response.msg);
      }
    },

    //=========================SKU商品=======================
    //=========================轮播图========================
    // 修改状态(1.上架 0.下架)
    modifyState(index, state) {
      this.skuTableData.forEach(sku => {
        if (index === sku.index) {
          sku.isShelf = state;
        }
      });
    },
    // 上传之前
    beforeMultiUploadHandle(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/bmp" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传图片格式不支持!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    // 上传成功
    successMultiHandle(response, file, fileList) {
      if (response && response.code === 0) {
        debugger;
        this.dialogImageUrl = response.data.fileUrl;
        this.fileList.push(response.data);
        console.log(this.fileList);
      } else {
        this.$message.error(response.msg);
      }
    },
    handleRemove(file, fileList) {
      var tempFileList = [];
      this.fileList.forEach(f => {
        if (file.url != f.url) {
          tempFileList.push(f);
        }
      });
      this.fileList = tempFileList;
      console.log(file);
    },
    handlePreview(file) {
      console.log(file);
    },
    //=========================轮播图========================
    //==========================属性值标签移除事件====================
    handleClose(attrId, valueId) {
      this.checkedAttrList.forEach(attr => {
        if (attrId === attr.id) {
          var tempValueIds = attr.attrValueIds;
          var tempValueList = attr.attrValues;
          var checkedValues = [];
          var checkedValueIds = [];
          tempValueList.forEach(value => {
            if (valueId != value.id) {
              checkedValues.push(value);
              checkedValueIds.push(value.id);
            }
          });
          attr.attrValueIds = checkedValueIds;
          attr.attrValues = checkedValues;
        }
      });
      this.buildSkuDataList();
    },
    //==========================属性值标签移除事件====================
    /**
     * 批量操作
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * id:SDK_ID
     * val:SKU名称
     * type:类型
     */
    handleInput(id, val, type) {
      if (this.isBatch(id)) {
        this.skuTableData.map(item => {
          for (let i = 0; i < this.multipleSelection.length; i++) {
            if (
              item.mallGoodsSkuId === this.multipleSelection[i].mallGoodsSkuId
            ) {
              item[type] = val;
            }
          }
        });
      }
    },
    /**
     * 判断是否批量操作数据
     */
    isBatch(id) {
      let flag;
      for (let i = 0; i < this.multipleSelection.length; i++) {
        if (id === this.multipleSelection[i].mallGoodsSkuId) {
          flag = true;
          break;
        }
        flag = false;
      }
      return flag;
    }
  },
  mounted() {
    this.getDataList();
    this.getAttrDataList();
    this.init();
  }
};
</script>
